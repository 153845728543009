import React from "react";
import { Link } from "react-router-dom";
import footerImgSm from "../../assets/footer-mobile.webp";
import footerImgLg from "../../assets/footer.webp";
import "./footer.css";

const Footer = () => {
  return (
    <section className="footer-section">
      <div>
        <hr></hr>
      </div>
      <div className="footer">
        <div className="footer-menu">
          <Link
            className="text-shadow-sm decoration-none"
            to={"https://blog.findahome.com/property-gems/"}
          >
            Property Gems
          </Link>
          <Link
            className="text-shadow-sm decoration-none"
            to={"https://blog.findahome.com/education/"}
          >
            Buyer/Seller Education
          </Link>
          <Link
            className="text-shadow-sm decoration-none"
            to={"/find-an-agent"}
          >
            Find An Agent
          </Link>
          <Link className="text-shadow-sm decoration-none" to={"/user-login"}>
            Agent Login
          </Link>
          <Link className="text-shadow-sm decoration-none" to={"/contact-us"}>
            Contact Us
          </Link>
        </div>

        <img
          className="footer-image"
          alt="A row of inviting houses"
          srcSet={`${footerImgSm} 500w, ${footerImgLg} 700w`}
          sizes="(max-width: 500px) 500px, (max-width: 700px) 700px, 1280px"
          width={"740px"}
          height={"165px"}
          src={footerImgLg}
        />
        <p className="jason text-shadow-sm">
          Jason Galaz, eXp Realty. #344914 p: (888)519-5113
        </p>
        <p className="brain-jar-link">
          Built by&nbsp;
          <a
            className="decoration-none"
            href="https://brainjar.net/"
            target="blank"
          >
            Brain Jar
          </a>
        </p>
      </div>
    </section>
  );
};

export default Footer;
